<template>
  <div style="min-height: 570px;position:relative;">
      <div v-if="mapData">
        <div style="position:absolute;top:20px;left:90px;border-radius:5px;background-color:#fff;z-index:90;max-width:150px;" v-if="content.show_constructor_categories">
          <v-select
            style="flex: 0.5 1 10%;"
            flat
            dense
            outlined
            hide-details
            placeholder="Select position"
            :items="mapCategories"
            class="canvas__select"
            v-model="selectedCategoryName"
          >
          </v-select>
        </div>
        <GmapMap
        ref="gmap"
        :zoom="+zoom"
        :center="{ lat: +center.lat, lng: +center.lon }"
        :options="{
            streetViewControl: mapData.show_view_street_map_control,
            mapTypeControl: mapData.map_type,
            zoomControl: mapData.show_zoom,
            fullscreenControl: mapData.show_fullscreen,
        }"
        map-type-id="terrain"
        style="width: 100%; height: 570px"
        >
        <div v-for="pointGroup in mapData.point_categories" :key="pointGroup.id+'bg'">
        <GmapMarker
            v-for="(point,index) in pointGroup.points" :key="point.id+'gmap'"
            :position="{
            lat: +point.coordinates.lat,
            lng: +point.coordinates.lon,
            }"
            :icon=" {
              path: mapIcons.shapes[content.styles.marker_shape],
              fillColor: content.styles.marker_shape_color,
              fillOpacity: 1,
              strokeColor: '',
              strokeWeight: 0
            }"
            :clickable="true"
            @click="pointClick(index)"
        >
        </GMapMarker>
</div>
<div v-for="pointGroup in mapData.point_categories" :key="pointGroup.id+'icon'">

        <GmapCustomMarker
            v-for="(point,index) in pointGroup.points" :key="point.id+'custom'"
            :marker="{
            lat: +point.coordinates.lat,
            lng: +point.coordinates.lon,
            }"
            :offsetY="-15"
            @click="pointClick(index)"
            >
          <span v-show="point.icon"
                @click="pointClick(index)" :class="`map-icon map-icon-${point.icon.split(' ').join('-')}`" style="font-size:20px;" :style="`color:${content.styles.marker_icon_color};`"></span>
        </GmapCustomMarker>
</div>
        </GmapMap>
        <div style="padding:15px;border-radius:5px;position:absolute;bottom:5px;width:345px;overflow: hidden;" v-show="mapData">
              <swiper
                ref="swiper"
                :options="swiperOptions"
                @click-slide="(ind) => sliderClick(ind)">
                <swiper-slide v-for="point in sliderPoints" :key="point.id+'swiper'" :style="`border:1px solid ${content.styles.border_color};background:${content.styles.bg_color};border-radius:${content.styles.radius}px;min-height:${content.styles.block_height}px;width:${content.styles.block_width}px;`">
                    <div :class="`point_${point.id}`" v-show="point.main_image ==='1'" style="max-width:100%;margin:0 auto;display:block;overflow:hidden;" :style="`border-top-right-radius:${content.styles.radius}px;border-top-left-radius:${content.styles.radius}px;height:${content.styles.image_height}px;width:${content.styles.image_width}%;`"></div>
                    <img v-show="point.main_image!=='1'&&point.main_image" :src="point.main_image" :alt="point.main_image" style="max-width:100%;margin:0 auto;display:block;" :style="`border-top-right-radius:${content.styles.radius}px;border-top-left-radius:${content.styles.radius}px;height:${content.styles.image_height}px;width:${content.styles.image_width}%;`">
                    <div style="padding:10px;display:flex;flex-direction:column;">
                        <div :style="`color:${content.styles.title_color}`">{{point.name[landing.current_lang]}}</div>
                        <div v-html="point.description[landing.current_lang]"></div>
                      <v-btn :href="point.object_url" v-if="point.object_url" small color="primary" outlined>More</v-btn>
                    </div>
                </swiper-slide>
              </swiper>
        </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {mapState} from "vuex"
import mapIcons from "@/assets/map-icons/js/map-icons.js"
import "@/assets/map-icons/fonts/map-icons.ttf"
import "@/assets/map-icons/css/map-icons.min.css"
import GmapCustomMarker from 'vue2-gmap-custom-marker';

export default {
  name: "typeMapPointsPreview",
  components:{
      Swiper,
      SwiperSlide,
      GmapCustomMarker
  },
  props:['content','item',"selectedMenuID"],
  data() {
      return {
        swiperOptions: {
        grabCursor: true,
        slidesPerView: "auto",
        spaceBetween: 5,
        mapIcons:null
        },
        center:{
          lon:"15.98",
          lat:"45.98"
        },
        zoom:"0",
        selectedCategoryName:"All"
      }
  },
  mounted() {
    if(this.mapData){
    this.center = this.mapData.coordinates;
    this.zoom = this.mapData.zoom;
    }
  },
  watch:{
    "mapData.zoom"(val){
      if(val){
        this.zoom = val
      }
    },
    "mapData.coordinates.lat"(val){
      if(val){
        this.center.lat = val
      }
    },
    "mapData.coordinates.lon"(val) {
      if (val) {
        this.center.lon = val
      }
    },
    "selectedCategory"(val){
        if(val){
          this.selectedCategoryName = val;
        }else{
          this.selectedCategoryName = 'All';
        }
      },
  },
  computed: {
    ...mapState("points", ["mapDataList",'selectedCategory']),
    ...mapState(['landing']),
    mapData(){
        let id;
        if(this.item.type === 10){
            id = +(this.selectedMenuID+""+this.item.id+'000');
        }else{
            id = this.item.id;
        }
      if(this.mapDataList){
        return this.mapDataList.find(x => x.menu_id ===id)
      }else{
        return null
      }
    },
    mapCategories(){
      if(this.mapDataList){
        const candidate = this.mapDataList.find(x => x.menu_id === this.item.id);
        if(candidate){
          return candidate.point_categories.reduce((acc,item) => {
            acc.push(item.name[this.landing.current_lang]);
            return acc
          },['All'])
        }else{
          return [];
        }
      }else{
        return []
      }
    },
    sliderPoints(){
      if(this.mapDataList){
        const candidate = this.mapDataList.find(x => x.menu_id === this.item.id);
        if(candidate){
          if(this.selectedCategoryName !== 'All'){
            return candidate.point_categories.reduce((acc,item) => {
              if(item.name[this.landing.current_lang] === this.selectedCategoryName){
                if(item.points){
                  acc = item.points;
                }else{
                  acc = [];
                }
              }
              return acc
            },[])
          }else{
          return candidate.point_categories.reduce((acc,item) => {
            acc = [...acc,...item.points]
            return acc
          },[])

          }
        }else{
          return []
        }
      }else{
        return []
      }
    }


  },
  beforeCreate(){
    this.mapIcons = mapIcons

  },
  methods: {
    sliderClick(ind){
      this.zoom = 17;
      this.center.lat = this.sliderPoints[ind].coordinates.lat
      this.center.lon = this.sliderPoints[ind].coordinates.lon;
    },
    pointClick(index) {
      this.$refs.swiper.$swiper.slideTo(index);
    }
  },
};
</script>

<style lang="scss">
.map-icon {
  color:#fff;
}
</style>
